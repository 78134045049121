import { ApolloLink, Observable, ObservableSubscription } from '@apollo/client';
import { getOperationAST, GraphQLError } from 'graphql';

import { newRelicAddPageAction } from '~/utils/newrelic';
import {
  NEW_RELIC_PAGE_ACTION_NAME,
  NEW_RELIC_REQUEST_STATUS,
} from '~/utils/constants/newrelic';

const loggerLink = new ApolloLink((operation, forward) => {
  const startDate = new Date();

  const saveLogToNewRelic = (errors?: ReadonlyArray<GraphQLError>) => {
    const endDate = new Date();
    const duration = endDate.getTime() - startDate.getTime();

    // determine the operation type (mutation, query, or subscription)
    const operationAST = getOperationAST(operation.query);
    // access request and response information
    const { variables } = operation;

    const status = operation.getContext()?.response?.status;

    if (status) {
      // store log data to new relic
      newRelicAddPageAction(NEW_RELIC_PAGE_ACTION_NAME.GRAPHQL_REQUEST, {
        operationName: operationAST?.name?.value,
        operationType: operationAST?.operation,
        variables,
        statusCode: status,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        duration,
        status: errors
          ? NEW_RELIC_REQUEST_STATUS.ERROR
          : NEW_RELIC_REQUEST_STATUS.SUCCESS,
        errors,
      });
    }
  };

  return new Observable((observer) => {
    let sub: ObservableSubscription;
    try {
      sub = forward(operation).subscribe({
        next: (response) => {
          saveLogToNewRelic(response?.errors);
          observer.next(response);
        },
        error: (err) => {
          saveLogToNewRelic(err);
          observer.error(err);
        },
        complete: () => {
          observer.complete();
        },
      });
    } catch (err) {
      observer.error(err);
    }

    return () => {
      if (sub) sub.unsubscribe();
    };
  });
});

export default loggerLink;
