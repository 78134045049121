import isNil from 'lodash.isnil';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Nullable, NullableObject } from '~/types';
import { parseValue } from '~/utils/helper';

export type TRrdNavigateOptions =
  | {
      replace?: boolean;
      state?: any;
    }
  | undefined;

export type QueryStateValue =
  | string
  | number
  | boolean
  | any[]
  | Record<string, any>
  | null
  | undefined;
export type UseQueryStateSetterFunctionType<T = any> = (prev: T) => T;
export type UseQueryStateSetterType<T = any> =
  | T
  | UseQueryStateSetterFunctionType<T>;

export const useQueryState = <T extends QueryStateValue>(
  key: string,
  defaultValue: T,
): [
  T,
  (
    val: UseQueryStateSetterType<Nullable<T>>,
    navigateOptions?: TRrdNavigateOptions,
  ) => void,
] => {
  const [params, setParams] = useSearchParams();

  const value = useMemo<T>(() => {
    const val = params.get(key);
    if (!val) return defaultValue;
    return parseValue(val);
  }, [key, defaultValue, params]);

  const setValue = useCallback(
    (
      arg: UseQueryStateSetterType<Nullable<T>>,
      navigateOptions?: TRrdNavigateOptions,
    ) => {
      if (typeof arg === 'function') arg = arg(value);
      if (isNil(arg)) params.delete(key);
      else {
        const argStr =
          typeof arg === 'object' ? JSON.stringify(arg) : arg.toString();
        params.set(key, argStr);
      }
      setParams(params, navigateOptions);
    },
    [params, setParams, key, value],
  );

  return [value, setValue];
};

export const useMultiQueryState = <T extends Record<string, QueryStateValue>>(
  defaultValues: T,
): [
  T,
  (
    val: UseQueryStateSetterType<NullableObject<T>>,
    navigateOptions?: TRrdNavigateOptions,
  ) => void,
] => {
  const [params, setParams] = useSearchParams();

  const values = useMemo<T>(() => {
    const values = defaultValues;
    params.forEach((val, key: keyof T) => {
      if (!val) return;
      const parsedValue = parseValue(val);
      values[key] = parsedValue;
    });
    return values;
  }, [JSON.stringify(defaultValues), params]);

  const setValues = useCallback(
    (
      arg: UseQueryStateSetterType<NullableObject<T>>,
      navigateOptions?: TRrdNavigateOptions,
    ) => {
      if (typeof arg === 'function') arg = arg(values);
      for (const key in arg) {
        const argVal = arg[key];
        if (isNil(argVal)) params.delete(key);
        else {
          const argValStr =
            typeof argVal === 'object'
              ? JSON.stringify(argVal)
              : argVal.toString();
          params.set(key, argValStr);
        }
      }
      setParams(params, navigateOptions);
    },
    [params, setParams, values],
  );

  return [values, setValues];
};
