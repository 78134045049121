import { MainContent, Box, Stack, BoxProps } from '@kitalulus/web-ui-kit';

import { useAppSelector } from '~/hooks/use-store';
import AuthLayoutCopyRight from '~/components/AuthLayoutV2/AuthLayoutCopyRight';

type CustomMainContentProps = BoxProps & {
  showCopyright?: boolean;
};

const CustomMainContent = ({
  children,
  showCopyright,
  ...props
}: CustomMainContentProps) => {
  const bannerFreeTrial = useAppSelector((state) => state.bannerFreeTrial);

  return (
    <Box bgcolor={props.bgcolor}>
      <MainContent
        {...(props as typeof MainContent)}
        sx={{
          main: {
            display: 'flex',
            minHeight: 'inherit',
            flexDirection: 'column',
            gap: 4,
          },
          ...(props.sx as typeof MainContent),
        }}
      >
        <Stack>
          <Box height={bannerFreeTrial.height} />
          {children}
        </Stack>
        {showCopyright && (
          <Stack alignItems="flex-end" pb={4}>
            <AuthLayoutCopyRight />
          </Stack>
        )}
      </MainContent>
    </Box>
  );
};

export default CustomMainContent;
