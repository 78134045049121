import {
  Typography,
  Avatar,
  Stack,
  Tooltip,
  brandColors,
} from '@kitalulus/web-ui-kit';

import { PackageBadge } from '~/components/PackageSubscription';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';
import { EpCompanyVerificationStatus } from '~/types/graphql/graphql';
import { AccountStatusProps } from './AccountStatus.types';
import { InfoIcon } from '../Icons/InfoIcon';
import TruncateText from '../TruncateText';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const AccountStatus = ({
  avatarSrc,
  displayName,
  status,
  statusDisplayName,
  statusDisplayNotice,
  avatarOnly,
  packageName,
}: AccountStatusProps) => {
  const { t } = useTranslation();
  const isMonetizationActive = useRemoteConfigValue('ff_monetization');

  const avatarSize = avatarOnly ? '34px' : '40px';

  const getVerificationColor = useMemo(() => {
    switch (status) {
      case EpCompanyVerificationStatus.Verified:
        return 'success.main';
      case EpCompanyVerificationStatus.OnHold:
      case EpCompanyVerificationStatus.Pending:
        return brandColors.warning[600];
      default:
        return 'error';
    }
  }, [status]);

  const packageBadgeNotice = useMemo(() => {
    if (status === EpCompanyVerificationStatus.OnHold) {
      return t('company:packageBadgeNotice.onHold');
    }
    return '';
  }, [status]);

  return (
    <Stack
      sx={{ bgcolor: 'grey.100' }}
      p={avatarOnly ? '10px' : '18px'}
      borderRadius="12px"
      gap={1.5}
    >
      <Stack
        direction="row"
        spacing={1.5}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Avatar
          src={avatarSrc}
          displayName={displayName ?? ''}
          sx={{ width: avatarSize, height: avatarSize }}
        />

        {!avatarOnly && (
          <Stack flex={1}>
            <TruncateText noOfLines={1}>
              <Typography variant="subtitle2">{displayName}</Typography>
            </TruncateText>
            <Stack direction="row" gap={0.75}>
              <TruncateText noOfLines={1}>
                <Typography variant="body2" color={getVerificationColor}>
                  {statusDisplayName}
                </Typography>
              </TruncateText>
              {statusDisplayNotice && (
                <Tooltip
                  title={
                    <Typography variant="caption">
                      {statusDisplayNotice}
                    </Typography>
                  }
                  PopperProps={{
                    sx: {
                      whiteSpace: 'pre-line',
                      textAlign: 'center',
                      '& .MuiTooltip-tooltip': {
                        p: '12px',
                      },
                    },
                  }}
                  arrow
                >
                  <span>
                    <InfoIcon
                      sx={{
                        color: brandColors.secondary[300],
                        fontSize: '22px',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>

      {isMonetizationActive && packageName && !avatarOnly && (
        <PackageBadge packageName={packageName} notice={packageBadgeNotice} />
      )}
    </Stack>
  );
};

export default AccountStatus;
