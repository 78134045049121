import { useMutation } from '@apollo/client';
import { MUTATION_INVITE_JOB_VACANCY_CANDIDATE } from '~/graphql/candidate-recommendation';
import {
  InviteJobVacancyCandidateMutation,
  InviteJobVacancyCandidateMutationVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch } from '~/hooks/use-store';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { useTranslation } from 'react-i18next';

export const useCandidateRecommendationInvite = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [handleInvite, { loading: inviteLoading }] = useMutation<
    InviteJobVacancyCandidateMutation,
    InviteJobVacancyCandidateMutationVariables
  >(MUTATION_INVITE_JOB_VACANCY_CANDIDATE, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ inviteJobVacancyCandidate }) => {
      const { isSuccess } = inviteJobVacancyCandidate;
      if (isSuccess) {
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'success',
            message: t(
              'candidate-recommendation-detail:snackbarMesseges.successInvite',
            ),
            closeable: true,
          }),
        );
      }
    },
  });

  const inviteCandidate = async (id: string, emailContent?: string) => {
    const res = await handleInvite({
      variables: {
        data: {
          id: id,
          note: emailContent,
        },
      },
    });
    const data = res?.data?.inviteJobVacancyCandidate;
    if (!data) throw new Error('Unknown error');
    return data;
  };

  return { inviteCandidate, inviteLoading };
};
