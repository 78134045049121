import {
  Stack,
  brandColors,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import {
  CardExperience,
  CardEducation,
  CardSalary,
  CardContacts,
  CardSkill,
} from '~/components/ProfileDetail/Cards';
import { ICardEducationItem } from '~/components/ProfileDetail/Cards/CardEducation';

import { useMemo } from 'react';
import {
  EpExperience,
  EpJobApplicationEpStatusEnum,
} from '~/types/graphql/graphql';
import { TApplicantDetailState } from '~/store/features/applicant-detail-slice';
import { TCandidateRecommendationDetailState } from '~/store/features/candidate-recommendation-detail-slice';
import { PROFILE_VARIANTS } from '~/utils/constants/profile';
import {
  isValidDate,
  joinNullableStrings,
  nullingCoalescing,
} from '~/utils/helper';
import EmailIcon from '~/components/Icons/EmailIcon';
import WhatssappIcon from '~/components/Icons/WhatsappIcon';
import { ICardContactsItem } from '~/components/ProfileDetail/Cards/CardContacts';
import Analytics, { ANALYTICS_EVENTS } from '~/utils/analytics';
import * as mpe from '~/utils/mixpanel';
import { generateWhatsappLink } from '~/utils/whatsapp';
import { Nullable } from '~/types';
import {
  useApplicantDetail,
  useMutateApplicantStatus,
} from '~/hooks/applicants';
import useApplicants from '~/hooks/use-applicants';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setApplicantsFetchStatusDataTable } from '~/store/features/applicants';
import { FETCH_STATUS } from '~/utils/constants';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';
import { MixpanelApplicantScreeningQuestionProperties } from '~/types/mixpanel';
import useIDBApplicantScreeningQuestionFilter from '~/hooks/index-db/use-idb-applicant-screening-question-filter';
import { useApplicantFreePlan } from '~/hooks/applicants/use-applicant-free-plan';

type ContentsPropsType = {
  profileData: TCandidateRecommendationDetailState | TApplicantDetailState;
  variant: PROFILE_VARIANTS;
};
const Contents = ({ profileData, variant }: ContentsPropsType) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showSkills = useRemoteConfigValue('ff_show_profile_skills');

  const { getApplicants, getApplicantsSummary } = useApplicants();
  const { queryApplicantDetail } = useApplicantDetail();
  const { doChangeStatusApplicantsFromPopup } = useMutateApplicantStatus();
  const { vacancyDetail, vacancyDetailV2, applicants } = useAppSelector(
    (state) => state,
  );

  const { isOnQuota, max, handleOnExplorePackages } = useApplicantFreePlan();

  const { displayData } = applicants;
  const { getVacancyFilter, applicantScreeningQuestionFilter } =
    useIDBApplicantScreeningQuestionFilter();

  const isContactRestricted = useMemo(() => {
    const profil = profileData as TApplicantDetailState;
    return profil?.userProfile?.isContactRestricted;
  }, [profileData?.userProfile]);

  const isContactMasked = useMemo(() => {
    const profil = profileData as TApplicantDetailState;
    return profil?.isMasked;
  }, [profileData]);

  const phoneNumber =
    profileData?.userProfile?.phoneNumber?.replace('+', '') ?? '-';
  const userEmail = profileData?.userProfile?.userEmail ?? '-';

  const educationSubLabel2TestId = useMemo(() => {
    if (variant === PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION)
      return 'lbCandidateRecommendationEducationPeriod';
    if (variant === PROFILE_VARIANTS.APPLICANT)
      return 'lbApplicantEducationPeriod';
    return '';
  }, [variant]);

  const haveSetIdealAnswer = useMemo(() => {
    if (vacancyDetail?.id) return !!getVacancyFilter(vacancyDetail?.id);
  }, [JSON.stringify(applicantScreeningQuestionFilter), vacancyDetail?.id]);

  const handleChangeStatus = async () => {
    if (!profileData?.id) return;
    const profil = profileData as TApplicantDetailState;

    const mpeScreeningQuestionProps = ():
      | MixpanelApplicantScreeningQuestionProperties
      | undefined => {
      // Only send property if API request success
      if (
        vacancyDetailV2?.screeningQuestionsFilter?.fetchStatus ===
        FETCH_STATUS.RESOLVED
      ) {
        const haveScreeningQuestions =
          vacancyDetailV2?.screeningQuestionsFilter?.data?.length;

        const selectedProfile = displayData?.find(
          ({ id }) => id === profileData?.id,
        );

        return {
          isScreeningQuestion: !!haveScreeningQuestions,
          isAnswerScreeningQuestionSet: haveSetIdealAnswer,
          isFullScoreScreeningQuestion: haveSetIdealAnswer
            ? !!selectedProfile?.isFullScoreScreeningQuestion
            : 'not set',
        };
      }
    };

    mpe.applicantProceedSingle({
      currentApplicantStatus: EpJobApplicationEpStatusEnum.Pending,
      newApplicantStatus: EpJobApplicationEpStatusEnum.OnProgress,
      appliedDate: profil?.createdAt,
      isRecommendation: !!profil?.matchingResult,
      section: 'preview contact',
      vacancyCode: nullingCoalescing(vacancyDetail?.code, ''),
      ...mpeScreeningQuestionProps(),
      isOnQuota,
    });
    const isSuccess = await doChangeStatusApplicantsFromPopup(
      profileData.id,
      EpJobApplicationEpStatusEnum.OnProgress,
    );

    if (isSuccess) {
      dispatch(setApplicantsFetchStatusDataTable(FETCH_STATUS.PENDING));
      getApplicants();
      getApplicantsSummary();
      await queryApplicantDetail(profileData.id, false);
    }
  };

  const handleContactPopupClose = () => {
    mpe.applicantClosePopover({
      section: 'preview contact',
    });
  };

  const restrictedContact = [
    {
      label: 'Email',
      contact: userEmail,
      icon: <EmailIcon sx={{ width: '21px', color: '#3366FF' }} />,
      popuptitle: t('applicant-detail:hidePreviewContactPopup.title'),
      popupBody: t('applicant-detail:hidePreviewContactPopup.body'),
      handlePopupAccept: handleChangeStatus,
      handlePopupClose: handleContactPopupClose,
      lookButtonProps: { 'data-test-id': 'btnSeeContactEmail' },
    },
    {
      label: 'Whatsapp',
      contact: phoneNumber,
      icon: <WhatssappIcon sx={{ width: '20px', color: '#229A16' }} />,
      popuptitle: t('applicant-detail:hidePreviewContactPopup.title'),
      popupBody: t('applicant-detail:hidePreviewContactPopup.body'),
      handlePopupAccept: handleChangeStatus,
      handlePopupClose: handleContactPopupClose,
      lookButtonProps: { 'data-test-id': 'btnSeeContactWhatsapp' },
    },
  ];

  const contact: Nullable<ICardContactsItem[]> = useMemo(() => {
    if (variant === PROFILE_VARIANTS.CANDIDATE_RECOMMENDATION) return;
    const profil = profileData as TApplicantDetailState;
    return [
      {
        label: 'Email',
        contact: userEmail,
        icon: <EmailIcon sx={{ width: '21px', color: '#3366FF' }} />,
        currentStatus: profil?.companyStatus as EpJobApplicationEpStatusEnum,
        appliedDate: isValidDate(profil?.createdAt)
          ? new Date(profil?.createdAt)?.toISOString()
          : '',
        onClick: () => {
          Analytics.sendEvent(ANALYTICS_EVENTS.APPLICANT_CONTACT);
          mpe.applicantContact({
            contactType: 'email',
            currentApplicantStatus:
              profil?.companyStatus as EpJobApplicationEpStatusEnum,
            appliedDate: isValidDate(profil?.createdAt)
              ? new Date(profil?.createdAt).toISOString()
              : '',
          });
          location.href = `mailto:${profil?.userProfile?.userEmail}`;
        },
        copyIconProps: { 'data-test-id': 'btnCopyEmail' },
      },
      {
        label: 'Whatsapp',
        contact: phoneNumber,
        icon: <WhatssappIcon sx={{ width: '18px', color: '#229A16' }} />,
        currentStatus: profil?.companyStatus as EpJobApplicationEpStatusEnum,
        appliedDate: isValidDate(profil?.createdAt)
          ? new Date(profil?.createdAt).toISOString()
          : '',
        onClick: () => {
          Analytics.sendEvent(ANALYTICS_EVENTS.APPLICANT_CONTACT);
          mpe.applicantContact({
            contactType: 'whatsapp',
            currentApplicantStatus:
              profil?.companyStatus as EpJobApplicationEpStatusEnum,
            appliedDate: new Date(profil?.createdAt).toISOString(),
          });

          window.open(
            generateWhatsappLink(profil?.userProfile?.phoneNumber ?? '', ''),
            '_blank',
          );
        },
        copyIconProps: { 'data-test-id': 'btnCopyWhatsapp' },
      },
    ];
  }, [profileData]);

  const profilContact = useMemo(() => {
    if (isContactRestricted) return restrictedContact;
    return contact;
  }, [isContactRestricted, profileData]);

  // Set Education
  const education = useMemo<ICardEducationItem[]>(() => {
    if (profileData?.userProfile?.educations?.length) {
      return profileData?.userProfile?.educations?.map((item) => ({
        label: item?.educationInstitution?.name?.toLowerCase() ?? '-',
        subLabel: joinNullableStrings(
          [item?.educationLevel, item?.educationProgram?.name?.toLowerCase()],
          '∙',
        ),
        subLabel2: item?.periodStr ?? '-',
        subLabel2TestId: educationSubLabel2TestId,
        description: item?.description,
      }));
    }
    return [];
  }, [profileData, educationSubLabel2TestId]);

  // Set Experience
  const experiences = useMemo<EpExperience[]>(() => {
    if (profileData?.userProfile?.experiences?.length) {
      return profileData?.userProfile.experiences.map((item) => ({
        jobFunction: item?.jobFunction ?? '-',
        companyName: item?.companyName ?? '-',
        description: item?.description ?? '',
        periodStr: item?.periodStr ?? '-',
        employmentTypeStr: item?.employmentTypeStr ?? '-',
        name: item?.name ?? '-',
        skills: item?.skills ?? '-',
        userProfileId: item?.userProfileId ?? '-',
        startYear: item?.startYear ?? '-',
        endYear: item?.endYear ?? '-',
        jvRole: item?.jvRole ?? null,
        jvSpecializationRoleId: item?.jvSpecializationRoleId ?? '-',
        id: item?.id ?? '-',
        __typename: 'EpExperience',
      }));
    }
    return [];
  }, [profileData]);

  const maskContent = useMemo(() => {
    if (!isContactMasked) return undefined;
    return {
      containerProps: {
        'data-test-id': 'ctApplicantPreviewContactMaskContainer',
      },
      title: t('applicant:previewMaskedContent.title'),
      message: t('applicant:previewMaskedContent.message', { max }),
      button: {
        onClick: handleOnExplorePackages,
        'data-test-id': 'btnApplicantPreviewContactMaskCTA',
      },
      variant: 'preview' as const,
    };
  }, [isContactMasked, max]);

  return (
    <Stack direction="column" gap={0.5} width="100%">
      {profilContact && (
        <CardContacts
          title={t('applicant-detail:contactApplicant')}
          info={t('applicant-detail:contactInfo')}
          boxTitleProps={{ sx: { bgcolor: 'initial' } }}
          stackContainerProps={{ direction: isMobile ? 'column' : 'row' }}
          items={profilContact}
          itemsProps={{
            ...(!isMobile && { justifyContent: 'start' }),
            sx: { width: '100%' },
            gap: 2,
          }}
          restricted={isContactRestricted}
          fullWidth={true}
          PaperProps={{
            elevation: 0,
            sx: {
              boxShadow: theme.customShadows['card'],
              border: `1px solid ${brandColors.neutral[400]}`,
              borderRadius: theme.spacing(2),
              mt: 2,
            },
          }}
          maskContent={maskContent}
        />
      )}

      <CardExperience
        title={t('applicant-detail:experience')}
        emptyState={t('applicant-detail:noExperience')}
        items={experiences}
        fullWidth
        spacing={3}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: 1,
            boxShadow: theme.customShadows['card'],
            border: `1px solid ${brandColors.neutral[400]}`,
            p: 3,
            borderRadius: theme.spacing(2),
            mt: 2,
          },
        }}
        {...(variant === PROFILE_VARIANTS.APPLICANT && {
          skillsTitleProps: { fontWeight: 400 },
        })}
      />
      {showSkills && (
        <CardSkill
          title={t('common:skill')}
          emptyState={t('applicant-detail:skillEmptyState')}
          paperProps={{
            elevation: 0,
            sx: {
              padding: 1,
              boxShadow: theme.customShadows['card'],
              border: `1px solid ${brandColors.neutral[400]}`,
              p: 3,
              borderRadius: theme.spacing(2),
              mt: 2,
            },
          }}
          skills={
            profileData?.userProfile?.skills?.map(
              (skill) => skill?.displayName ?? '-',
            ) ?? []
          }
        />
      )}
      <CardSalary
        title={t('applicant-detail:expectedSalary')}
        emptyState={t('applicant-detail:noSalary')}
        salary={profileData?.expectedSalaryStr}
        fullWidth
        spacing={4.5}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: 1,
            boxShadow: theme.customShadows['card'],
            border: `1px solid ${brandColors.neutral[400]}`,
            p: 3,
            borderRadius: theme.spacing(2),
            mt: 2,
          },
        }}
      />
      {education.length > 0 && (
        <CardEducation
          title={t('applicant-detail:education')}
          items={education}
          fullWidth
          spacing={4.5}
          PaperProps={{
            elevation: 0,
            sx: {
              padding: 1,
              boxShadow: theme.customShadows['card'],
              border: `1px solid ${brandColors.neutral[400]}`,
              p: 3,
              borderRadius: theme.spacing(2),
              mt: 2,
            },
          }}
        />
      )}
    </Stack>
  );
};

export default Contents;
