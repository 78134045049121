import { Checkbox, Typography, Stack } from '@kitalulus/web-ui-kit';
import { Dispatch, SetStateAction } from 'react';
import { maxSelectedRejectReasons } from '~/utils/validation';
import { ReasonsType } from '../ApplicantRejectDialog.types';

const Options = ({
  options,
  setReasons,
}: {
  options: Array<ReasonsType>;
  setReasons: Dispatch<SetStateAction<ReasonsType[]>>;
}) => {
  const handleSelect = (reason: ReasonsType) => {
    setReasons((prev) => {
      // handle check/uncheck
      const updatedReasons = prev.map((x) =>
        x.key === reason.key ? { ...x, checked: !reason.checked } : x,
      );

      // handle max selected reasons
      const totalselect = updatedReasons.filter((x) => x.checked).length;
      if (totalselect === maxSelectedRejectReasons)
        return updatedReasons.map((x) => ({
          ...x,
          disabled: !x.checked,
        }));

      // handle unique disabledKeys
      const disabledType = new Set(
        updatedReasons.filter((x) => x.checked).flatMap((x) => x.disabledKeys),
      );
      // handle disabled
      return updatedReasons.map((x) => ({
        ...x,
        disabled: disabledType.has(x.key),
      }));
    });
  };
  return (
    <Stack>
      {options.map((x) => (
        <Stack key={x.key} direction="row" alignItems="center">
          <Checkbox
            checked={x.checked}
            disabled={x.disabled}
            onClick={() => handleSelect(x)}
          />
          <Typography
            variant="body2"
            color={x.disabled ? 'text.disabled' : 'initial'}
          >
            {x.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default Options;
