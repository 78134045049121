import { useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

import { QUERY_JOB_VACANCY_CANDIDATE } from '~/graphql/candidate-recommendation';
import {
  setCandidateRecomedationDetailFetchStatus,
  setCandidateRecommendationDetail,
  setCandidateRecommendationDetailError,
} from '~/store/features/candidate-recommendation-detail-slice';
import { clearDialog } from '~/store/views/dialogs-slice';
import {
  JobVacancyCandidateQuery,
  JobVacancyCandidateQueryVariables,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';
import { GRAPHQL_ERROR_CODE } from '~/utils/constants/graphql-error-code';
import { generateQueryString } from '~/utils/helper';
import { useCandidateRecommendationPageQuery } from '~/hooks/page-query/use-candidate-recommendation-page-query';
import { useAppDispatch } from '../use-store';

export const useCandidateRecommendationDetail = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useCandidateRecommendationPageQuery();

  const [getCandidateRecommendationDetail, { loading }] = useLazyQuery<
    JobVacancyCandidateQuery,
    JobVacancyCandidateQueryVariables
  >(QUERY_JOB_VACANCY_CANDIDATE, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ jobVacancyCandidate }) => {
      if (!jobVacancyCandidate) return;
      dispatch(
        setCandidateRecommendationDetail({
          ...jobVacancyCandidate,
        }),
      );
    },
  });

  const queryCandidateDetail = (id: string) => {
    dispatch(setCandidateRecomedationDetailFetchStatus(FETCH_STATUS.PENDING));
    getCandidateRecommendationDetail({
      variables: {
        id: id,
      },
      onError: (error) => {
        const extensionCode = error.graphQLErrors.map(
          (error) => error.extensions?.code,
        );
        const isNotFound = extensionCode.includes(GRAPHQL_ERROR_CODE.NOT_FOUND);

        if (isNotFound) {
          dispatch(
            setCandidateRecommendationDetailError(GRAPHQL_ERROR_CODE.NOT_FOUND),
          );
          dispatch(clearDialog('profilePreviewDialog'));
          navigate(
            `${location.pathname}?${generateQueryString({
              id,
              ...queryParams,
            })}`,
            { replace: true },
          );
        }
      },
    });
  };

  return { queryCandidateDetail, loading };
};
