import { useEffect } from 'react';

import * as mpe from '~/utils/mixpanel';
import { useAppSelector } from './use-store';

export const useMixpanelUserProperties = () => {
  const { user, company, realtimeData } = useAppSelector((state) => state);
  const realtimeDataCompany = realtimeData.company;

  useEffect(() => {
    if (!user?.username) return;
    mpe.setUserProperties({
      id: user.username,
      email: user.username,
      name: user.displayName ?? '',
      phoneNumber: user.phoneNumber ? `62${user.phoneNumber}` : '',
      companyCode: company?.code ?? '',
      companyName: company?.displayName ?? '',
      companyIndustry: company?.jobCompanyIndustry?.name ?? '',
      companySize: company?.jobCompanySize?.size ?? '',
      companyType: company?.companyType ?? '',
      userRole: user?.roleStr ?? '',
      companyPIC: company?.accManager ?? '',
      companyStatus: realtimeDataCompany?.data?.company?.status ?? '',
      companyPackage: realtimeDataCompany?.data?.package?.name ?? '',
    });
  }, [user, company, realtimeData]);
};
