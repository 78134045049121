export enum NEW_RELIC_PAGE_ACTION_NAME {
  GRAPHQL_REQUEST = 'GRAPHQL_REQUEST',
  HTTP_REQUEST = 'HTTP_REQUEST',
  MIXPANEL_ANALYTIC = 'MIXPANEL_ANALYTIC',
  INDEXED_DB = 'INDEXED_DB',
}

export enum NEW_RELIC_REQUEST_STATUS {
  INITIALIZATION = 'INITIALIZATION',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
