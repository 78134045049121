import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EpJobVacancies } from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

export type TypeDashboardState = {
  summaryCount: {
    fetchStatus: FETCH_STATUS;
    totalNew: number;
    totalPending: number;
  };
  dataOnProgress: Partial<EpJobVacancies> & {
    fetchStatus: FETCH_STATUS;
  };
  dataPending: Partial<EpJobVacancies> & {
    fetchStatus: FETCH_STATUS;
  };
};

const initialState: TypeDashboardState = {
  summaryCount: {
    fetchStatus: FETCH_STATUS.IDLE,
    totalNew: 0,
    totalPending: 0,
  },
  dataOnProgress: {
    fetchStatus: FETCH_STATUS.IDLE,
  },
  dataPending: {
    fetchStatus: FETCH_STATUS.IDLE,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardSummaryCount: (
      state,
      action: PayloadAction<Partial<TypeDashboardState['summaryCount']>>,
    ) => {
      return {
        ...state,
        summaryCount: {
          ...state.summaryCount,
          ...action.payload,
        },
      };
    },
    setDashboardListOnProgress: (
      state,
      action: PayloadAction<Partial<TypeDashboardState['dataOnProgress']>>,
    ) => {
      return {
        ...state,
        dataOnProgress: {
          ...state.dataOnProgress,
          ...action.payload,
        },
      };
    },
    setDashboardListPending: (
      state,
      action: PayloadAction<Partial<TypeDashboardState['dataPending']>>,
    ) => {
      return {
        ...state,
        dataPending: {
          ...state.dataPending,
          ...action.payload,
        },
      };
    },
    clearDashboardList: (state) => {
      return {
        ...state,
        ...initialState.dataOnProgress,
        ...initialState.dataPending,
      };
    },
    clearDashboardSummaryCount: (state) => {
      return {
        ...state,
        ...initialState.summaryCount,
      };
    },
    clearDashboard: () => initialState,
  },
});

export const {
  setDashboardListOnProgress,
  setDashboardListPending,
  setDashboardSummaryCount,
  clearDashboard,
  clearDashboardList,
  clearDashboardSummaryCount,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
