import { Stack, Typography } from '@kitalulus/web-ui-kit';

import { PROFILE_LOCATION } from '~/utils/constants/profile';

import { EmptyStateProps } from './ScreeningQuestionAnswers.types';

const EmptyState = ({ location, resultStr }: EmptyStateProps) => {
  const isPreview = location === PROFILE_LOCATION.PREVIEW;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{ pt: isPreview ? 0 : '12px', pb: isPreview ? 0 : '36px' }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        data-test-id={
          isPreview
            ? 'lbNotAnswerScreeningQuestionPreviewApplicant'
            : 'lbNotAnswerScreeningQuestionDetailApplicant'
        }
      >
        {resultStr}
      </Typography>
    </Stack>
  );
};
export default EmptyState;
