import {
  Box,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Typography,
  brandColors,
} from '@kitalulus/web-ui-kit';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '~/components/LoadingButton';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { SubscriptionPlanConfirmationDialogV2Props } from './SubscriptionPlanConfirmationDialogV2.types';
import * as mpe from '~/utils/mixpanel';
import NumberTextField from '~/components/NumberTextField';
import InputAdornmentWithBorder from '~/components/InputAdornmentWithBorder';
import { phoneNumberMaxLength, phoneNumberMinLength } from '~/utils/validation';
import CloseIcon from '~/components/Icons/CloseIcon';

const SubscriptionPlanConfirmationDialogV2 = ({
  content,
  ...props
}: SubscriptionPlanConfirmationDialogV2Props) => {
  const {
    body,
    cancelLabel,
    imageSrc = '/illustrations/character-10-illustration.svg',
    onSubmit,
    pageLocation,
    submitLabel,
    title,
  } = content ?? {};
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [whatsapp, setWhatsapp] = useState('');
  const [whatsappError, setWhatsappError] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeWithMixpanel = useCallback(() => {
    if (pageLocation === 'onBoarding') mpe.onBoardingPremiumCancel();
    if (pageLocation === 'dialog') mpe.premiumOfferingCancel();
    handleClose();
  }, [pageLocation]);

  const handleClose = useCallback(() => {
    dispatch(closeDialog('subscriptionPlanConfirmationDialogV2'));
    setWhatsapp('');
    setWhatsappError('');
  }, []);

  const onWhatsappChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setWhatsapp(e.target.value);
    const isLessThanMin = e.target.value.length < phoneNumberMinLength;
    if (!e.target.value.length || !isLessThanMin) return setWhatsappError('');
    if (isLessThanMin) {
      setWhatsappError(
        t('auth:phoneNumberLengthValidation', {
          min: phoneNumberMinLength,
          max: phoneNumberMaxLength,
        }),
      );
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!onSubmit) return;
    setIsLoading(true);
    await onSubmit(whatsapp, handleClose);
    setIsLoading(false);
  }, [onSubmit, handleClose, whatsapp]);

  return (
    <Dialog
      maxWidth="xs"
      {...props}
      sx={{
        textAlign: 'center',
        '.MuiPaper-root': {
          maxWidth: '450px',
          width: '100%',
          p: { xs: 2, md: 3 },
          borderRadius: 2,
        },
        ...props.sx,
      }}
    >
      <Stack gap={5}>
        <Stack direction="row" justifyContent="end">
          <IconButton
            size="small"
            onClick={closeWithMixpanel}
            sx={{
              color: brandColors.grey[800],
              alignSelf: 'start',
            }}
            data-test-id="btnSubscriptionPlanConfirmationClose"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {imageSrc && (
          <Box
            component="img"
            src={imageSrc}
            alt="Subscription package confirmation illustration"
            width="auto"
            height="134px"
            data-test-id="imgSubscriptionPlanConfirmationImage"
          />
        )}
        <Stack gap={3}>
          <Stack gap={2}>
            {title && (
              <Typography
                component="div"
                variant="h5"
                color="text.primary"
                textAlign="center"
                data-test-id="lbSubscriptionPlanConfirmationTitle"
              >
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
            )}
            {body && (
              <Typography
                component="div"
                variant="body1"
                textAlign="center"
                color="text.primary"
                data-test-id="lbSubscriptionPlanConfirmationBody"
              >
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Typography>
            )}
            <NumberTextField
              data-test-id="sbSubscriptionPlanConfirmationWhatsapp"
              FormHelperTextProps={{
                // eslint-disable-next-line
                // @ts-ignore
                'data-test-id': 'lbSubscriptionPlanConfirmationError',
              }}
              onlyPositive
              required
              label={`${t(
                'subscription:subscriptionOfferV2.selectConfirmationNumberLabel',
              )}`}
              placeholder={t('vacancy-form:recruiterWAPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornmentWithBorder position="start">
                    <Typography variant="body1" color="success.main">
                      +62
                    </Typography>
                  </InputAdornmentWithBorder>
                ),
              }}
              inputProps={{
                maxLength: phoneNumberMaxLength,
                inputMode: 'numeric',
              }}
              sx={{
                flex: 1,
                width: '100%',
                '& .MuiInputLabel-asterisk': { color: brandColors.danger[500] },
                ...props.sx,
              }}
              value={whatsapp}
              onChange={onWhatsappChange}
              error={!!whatsappError}
              helperText={whatsappError}
            />
          </Stack>
          <DialogActions sx={{ justifyContent: 'space-between', gap: 1 }}>
            <LoadingButton
              variant="contained"
              sx={{ flex: 1, whiteSpace: { xs: 'initial', md: 'nowrap' } }}
              onClick={handleSubmit}
              loading={isLoading}
              disabled={!onSubmit || !whatsapp || !!whatsappError}
              data-test-id="btnSubscriptionPlanConfirmationSubmit"
            >
              {submitLabel ?? t('common:contactKitalulus')}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="inherit"
              sx={{ flex: 1 }}
              onClick={closeWithMixpanel}
              disabled={isLoading}
              autoFocus
              data-test-id="btnSubscriptionPlanConfirmationCancel"
            >
              {cancelLabel ?? t('common:later')}
            </LoadingButton>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SubscriptionPlanConfirmationDialogV2;
