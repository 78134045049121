import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';

import CloseIcon from '~/components/Icons/CloseIcon';
import LoadingButton from '~/components/LoadingButton';
import SlideUpDialogTransition from '~/components/Transistions/SlideUpDialogTransition';
import JobVacancyEthicContent from '~/components/VacancyForm/steps/VacancyFormFourthStep/JobVacancyEthicContent';
import { useAppDispatch } from '~/hooks/use-store';
import { clearDialog } from '~/store/views/dialogs-slice';

import { VacancyEthicCodeSubmitDialogProps } from './VacancyEthicCodeSubmitDialog.types';

const VacancyEthicCodeSubmitDialog = ({
  content,
  ...props
}: VacancyEthicCodeSubmitDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setIsChecked(e.target.checked);

  const handleOnClose = () => {
    dispatch(clearDialog('vacancyEthicCodeSubmitDialog'));
  };

  if (!content) return null;

  const { cancelProps, acceptProps } = content;

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      sx={{
        top: {
          xs: '10%',
          sm: 0,
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: 1000,
          padding: 0,
        },
      }}
      scroll={'paper'}
      data-test-id="ctVacancyCopyEthicCodePopup"
      {...(isMobile && { TransitionComponent: SlideUpDialogTransition })}
      {...props}
    >
      <Stack gap={2} sx={{ p: 3, pb: 1.5 }}>
        <Stack alignItems="center">
          <Divider
            sx={{
              display: isMobile ? 'flex' : 'none',
              width: 131,
              borderWidth: '1px',
              borderRadius: '1px',
              borderColor: '#000000',
            }}
          />
        </Stack>
        <DialogTitle sx={{ p: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="div"
              variant="h6"
              color="text.primary"
              data-test-id="lbVacancyCopyEthicCodePopupTitle"
            >
              {t('vacancy-ethic-code:vacancyEthicCodeApp')}
            </Typography>
            <IconButton sx={{ top: -10, right: -10 }} onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Stack gap={2}>
            <JobVacancyEthicContent />
          </Stack>
        </DialogContent>
      </Stack>
      <DialogActions
        sx={{
          width: '100%',
          p: 3,
          pt: 1.5,
          borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        }}
      >
        <Stack gap={{ xs: 3, sm: 1.5 }} sx={{ width: '100%' }}>
          <Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={isChecked} onChange={handleOnChange} />
                }
                label={
                  <Typography variant="subtitle2" marginLeft={1.5}>
                    {t('vacancy-form:jobVacancyEthicLabel')}
                  </Typography>
                }
                sx={{ marginLeft: 0 }}
              />
            </FormGroup>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={3}>
            <Button
              autoFocus
              color="primary"
              variant="outlined"
              data-test-id="btnVacancyCopyEthicCodePopupCancel"
              sx={{ minWidth: 86 }}
              {...cancelProps}
            >
              {t('vacancy-form:previewVacancy')}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={!isChecked}
              data-test-id="btnVacancyCopyEthicCodePopupConfirm"
              sx={{ minWidth: 86 }}
              {...acceptProps}
            >
              {t('vacancy-form:createVacancySubmit')}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyEthicCodeSubmitDialog;
