export enum MENU_NAMES {
  DASHBOARD = 'Dashboard',
  VACANCY = 'Vacancy',
  APPLICANTS = 'Applicants',
  CANDIDATE_RECOMMENDATION = 'CandidateRecommendation',
  ACCOUNT = 'Account',
}

export enum MIXPANEL_ACCOUNT_FAQ_LINK_POSITION {
  SIGN_IN = 'sign in',
  REGISTER_PAGE = 'register page',
  SELF_REGISTRATION = 'self registration',
  COMPANY_DATA_PAGE = 'company data page',
  SIDE_BAR = 'side bar',
  SUBSCRIPTION_PLAN_DIALOG = 'subscription plan dialog',
  SUBSCRIPTION_OFFER_DIALOG = 'subscription offer dialog',
}

export enum MIXPANEL_ACCOUNT_HELP_LINK_POSITION {
  SIGN_IN = 'sign in',
  REGISTER_PAGE = 'register page',
  SELF_REGISTRATION = 'self registration',
  COMPANY_DATA_PAGE = 'company data page',
  DASHBOARD = 'dashboard',
  FRAUD_PAGE = 'fraud page',
  ACCOUNT_VERIFICATION_INVALID_PAGE = 'account verification invalid page',
  SIGNIP_EXPIRED_PAGE = 'signup expired page',
  SELF_REGISTRATION_EXPIRED_PAGE = 'self-registration expired page',
  SELF_REGISTRATION_CONFIRM_PAGE = 'self-registration confirm page',
  CREATE_VACANCY = 'create vacancy',
  COPY_VACANCY = 'copy vacancy',
  CHANGE_VACANCY_STATUS = 'change vacancy status',
}
