import { Box, Tooltip, Typography } from '@kitalulus/web-ui-kit';
import { ReactElement, ReactNode } from 'react';

const TabTooltip = ({
  show,
  tooltipContext = '',
  children,
}: {
  show: boolean;
  tooltipContext?: ReactNode;
  children: ReactElement;
}) => {
  return (
    <Tooltip
      arrow
      title={
        show ? (
          <Box
            py={1}
            px={0.5}
            sx={{
              maxWidth: 200,
              textAlign: 'center',
            }}
          >
            <Typography variant="caption" component="div">
              {tooltipContext}
            </Typography>
          </Box>
        ) : (
          ''
        )
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default TabTooltip;
