export const unionResponseErrorTypes = [
  'EpJobVacancyError',
  'EpCreateJobVacancyError',
  'EpUpdateJobVacancyError',
  'EpOpenJobVacancyError',
  'EpCreateJobVacancyV3Error',
  'EpUpdateJobVacancyV3Error',
  'EpRepostJobVacancyError',
  'EpSyncPublishedJobVacancyError',
  'EpJobVacancyTierCategoryError',
  'EpSubmitContactedPhoneNumberResponseError',
  'EpActivateJobVacancyHighlightV2Error',
  'EpDeactivateJobVacancyHighlightV2Error',
  'EpOpenJobVacancyV3Error',
  'EpOnHoldJobVacancyV2Error',
  'EpCloseJobVacancyV2Error',
];
