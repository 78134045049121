import { useTheme, Dialog, Box, Stack, BoxProps } from '@kitalulus/web-ui-kit';
import { keyframes } from '@mui/material';
import { LoadingPage3DotsProps } from './LoadingPage3DotsDialog.types';

const LoadingPage3Dots = ({ content, ...props }: LoadingPage3DotsProps) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          textAlign: 'center',
          maxWidth: 508,
        },
      }}
      {...props}
    >
      <Stack
        alignItems="center"
        alignContent="center"
        sx={{
          backgroundColor: theme.palette.common.white,
          padding: '54px 24px',
          borderRadius: '16px',
        }}
      >
        <Loading3Dots sx={{ ...(!!content?.body && { mb: 3 }) }} />
        {content?.body}
      </Stack>
    </Dialog>
  );
};

const Loading3Dots = (props: BoxProps) => {
  const ellipsis1 = keyframes({
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
  });

  const ellipsis2 = keyframes({
    from: { transform: 'translate(0, 0)' },
    to: { transform: 'translate(24px, 0)' },
  });

  const ellipsis3 = keyframes({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(0)' },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '80px',
        height: '30px',
        '& div': {
          position: 'absolute',
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          background: '#1E88E5',
          animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
        },
        '& div:nth-of-type(1)': {
          left: '8px',
          animation: `${ellipsis1} 0.6s infinite`,
        },
        '& div:nth-of-type(2)': {
          left: '8px',
          animation: `${ellipsis2} 0.6s infinite`,
        },
        '& div:nth-of-type(3)': {
          left: '32px',
          animation: `${ellipsis2} 0.6s infinite`,
        },
        '& div:nth-of-type(4)': {
          left: '56px',
          animation: `${ellipsis3} 0.6s infinite`,
        },
        ...props.sx,
      }}
    >
      <div />
      <div />
      <div />
      <div />
    </Box>
  );
};

export default LoadingPage3Dots;
