import { Typography } from '@kitalulus/web-ui-kit';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS,
  MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS_V2,
  QUERY_JOB_APPLICATION_EP_STATUS_DROPDOWN,
} from '~/graphql/applicants';

import {
  EpJobApplicationEpStatusEnum,
  JobApplicationEpStatusDropdownQuery,
  UpdateBulkJobApplicationCompanyStatusMutation,
  UpdateBulkJobApplicationCompanyStatusMutationVariables,
  UpdateBulkJobApplicationCompanyStatusV2Mutation,
  UpdateBulkJobApplicationCompanyStatusV2MutationVariables,
} from '~/types/graphql/graphql';
import { useAppDispatch, useAppSelector } from '../use-store';
import { setApplicationOptionsStatus } from '~/store/options/application-options-slice';
import { FETCH_STATUS } from '~/utils/constants';
import { useCallback, useEffect } from 'react';
import { useAuthValue } from '../use-auth';
import {
  setApplicantsMutationStatusUpdateStatus,
  setApplicantsChangeStatus,
  setApplicantsFetchStatusDataTable,
  setApplicantsDisplayData,
  TypeDisplayData,
  setApplicantShowSelectBulkErrorCopywriting,
} from '~/store/features/applicants';
import { setAuthenticatedDialog } from '~/store/views/authenticated-dialog-slice';
import { Trans, useTranslation } from 'react-i18next';
import { clearDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { sleep } from '~/utils/helper';
import useApplicants from '../use-applicants';
import { useRemoteConfigValue } from '../firebase/use-remote-config';
import * as mpe from '~/utils/mixpanel';
import { setApplicantDetailIsUpdatingStatus } from '~/store/features/applicant-detail-slice';

export type ApplicantStatusUpdateParam = {
  ids: string[];
  status: EpJobApplicationEpStatusEnum;
  reasons: string[] | null;
};

export const useApplicantStatusOptions = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthValue();
  const { items } = useAppSelector((state) => state.applicationOptions.status);

  const pendingOptions = [
    EpJobApplicationEpStatusEnum.OnProgress,
    EpJobApplicationEpStatusEnum.Interview,
    EpJobApplicationEpStatusEnum.Accepted,
    EpJobApplicationEpStatusEnum.Rejected,
    EpJobApplicationEpStatusEnum.Saved,
  ];

  const onProgressOptions = [
    EpJobApplicationEpStatusEnum.Interview,
    EpJobApplicationEpStatusEnum.Accepted,
    EpJobApplicationEpStatusEnum.Rejected,
  ];

  const interviewOptions = [
    EpJobApplicationEpStatusEnum.Accepted,
    EpJobApplicationEpStatusEnum.Rejected,
  ];

  const savedOptions = [
    EpJobApplicationEpStatusEnum.OnProgress,
    EpJobApplicationEpStatusEnum.Interview,
    EpJobApplicationEpStatusEnum.Accepted,
    EpJobApplicationEpStatusEnum.Rejected,
  ];

  const [getJobApplicationEpStatuses] =
    useLazyQuery<JobApplicationEpStatusDropdownQuery>(
      QUERY_JOB_APPLICATION_EP_STATUS_DROPDOWN,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ jobApplicationEpStatusDropdown: statuses }) => {
          if (!statuses) return;
          dispatch(
            setApplicationOptionsStatus({
              items: statuses.map((d) => ({
                value: d?.key,
                label: d?.value,
              })),
              fetchStatus: FETCH_STATUS.RESOLVED,
            }),
          );
        },
      },
    );

  const queryJobApplicationEpStatuses = () => {
    dispatch(
      setApplicationOptionsStatus({ fetchStatus: FETCH_STATUS.PENDING }),
    );
    getJobApplicationEpStatuses();
  };

  const filterOptions = (status: EpJobApplicationEpStatusEnum) => {
    switch (status) {
      case EpJobApplicationEpStatusEnum.Pending:
        return items.map((x) => {
          const additional = { disabled: !pendingOptions.includes(x.value) };
          return { ...x, additional };
        });
      case EpJobApplicationEpStatusEnum.OnProgress:
        return items.map((x) => {
          const additional = { disabled: !onProgressOptions.includes(x.value) };
          return { ...x, additional };
        });
      case EpJobApplicationEpStatusEnum.Interview:
        return items.map((x) => {
          const additional = { disabled: !interviewOptions.includes(x.value) };
          return { ...x, additional };
        });
      case EpJobApplicationEpStatusEnum.Saved:
        return items.map((x) => {
          const additional = { disabled: !savedOptions.includes(x.value) };
          return { ...x, additional };
        });
      default:
        return [];
    }
  };

  const getLabel = (value: EpJobApplicationEpStatusEnum) => {
    return items.find((x) => x.value === value)?.label;
  };

  const getValue = (label: string) => {
    return items.find((x) => x.label === label)?.value;
  };

  const getItemAsDisabled = (value: EpJobApplicationEpStatusEnum) => {
    const item = items.find((x) => x.value === value);
    return { ...item, additional: { disabled: true } };
  };

  useEffect(() => {
    if (!isAuthenticated || items.length) return;
    queryJobApplicationEpStatuses();
  }, [isAuthenticated]);

  return { filterOptions, getLabel, getValue, getItemAsDisabled };
};

export const useMutateApplicantStatus = () => {
  const { t } = useTranslation();
  const { getApplicants, getApplicantsSummary } = useApplicants();
  const { displayData } = useAppSelector((state) => state.applicants);
  const isUseApplicantUpdateStatusV2Active = useRemoteConfigValue(
    'ff_use_applicant_update_status_v2',
  );
  const dispatch = useAppDispatch();

  const [updateStatusApplicantsBulk, { loading }] = useMutation<
    UpdateBulkJobApplicationCompanyStatusMutation,
    UpdateBulkJobApplicationCompanyStatusMutationVariables
  >(MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS);

  const [updateStatusApplicantsBulkV2] = useMutation<
    UpdateBulkJobApplicationCompanyStatusV2Mutation,
    UpdateBulkJobApplicationCompanyStatusV2MutationVariables
  >(MUTATION_UPDATE_BULK_JOB_APPLICATION_COMPANY_STATUS_V2);

  const changeApplicantStatus = async ({
    ids,
    status,
    reasons,
  }: ApplicantStatusUpdateParam) => {
    const res = await updateStatusApplicantsBulk({
      variables: {
        data: {
          ids,
          companyStatus: status,
          reasons: reasons,
        },
      },
    });

    const data = res?.data?.updateBulkJobApplicationCompanyStatus;
    if (!data) throw new Error('Unknown error');
    return data;
  };

  const changeApplicantStatusV2 = async ({
    ids,
    status,
    reasons,
  }: ApplicantStatusUpdateParam) => {
    const res = await updateStatusApplicantsBulkV2({
      variables: {
        data: {
          ids,
          companyStatus: status,
          reasons: reasons,
        },
      },
    });

    const data = res?.data?.updateBulkJobApplicationCompanyStatusV2;
    if (!data) throw new Error('Unknown error');
    return data;
  };

  const closeDialogs = useCallback(() => {
    dispatch(clearDialog('applicantAcceptDialog'));
    dispatch(clearDialog('applicantRejectDialog'));
    dispatch(clearDialog('applicantInterviewDialog'));
    dispatch(clearDialog('applicantBulkDialog'));
  }, []);

  const closeBulkLoadingDialog = () => {
    dispatch(
      setAuthenticatedDialog({
        loadingPage3DotsDialog: {
          open: false,
        },
      }),
    );
  };

  const doChangeStatusApplicantsV2 = async (
    ids: string[],
    status: EpJobApplicationEpStatusEnum,
    reasons: string[] | null,
  ) => {
    const singleUpdate = ids.length === 1;
    dispatch(setApplicantsMutationStatusUpdateStatus(FETCH_STATUS.PENDING));

    if (!singleUpdate) {
      closeDialogs();
      dispatch(
        setAuthenticatedDialog({
          loadingPage3DotsDialog: {
            open: true,
            content: {
              body: (
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                  <Trans i18nKey="applicant:bulkPopup.loadingMessage" />
                </Typography>
              ),
            },
          },
        }),
      );
    }

    dispatch(setApplicantsChangeStatus(ids));
    const { isSuccess, data, error } = await changeApplicantStatusV2({
      ids,
      status,
      reasons,
    });
    dispatch(setApplicantsMutationStatusUpdateStatus(FETCH_STATUS.RESOLVED));
    closeDialogs();
    closeBulkLoadingDialog();

    if (!isSuccess) {
      return dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: error,
        }),
      );
    }

    // Handle Bulk
    if (!singleUpdate) {
      if (data?.countFailed === 0) {
        dispatch(setApplicantShowSelectBulkErrorCopywriting(false));
        dispatch(setApplicantsFetchStatusDataTable(FETCH_STATUS.PENDING));

        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'success',
            message: data.message,
          }),
        );

        getApplicants();
        getApplicantsSummary();
      }

      if (data?.countFailed) {
        dispatch(setApplicantShowSelectBulkErrorCopywriting(true));
        let displayedApplicants: TypeDisplayData[] = [...displayData];
        const successID = data.result?.filter((x) => x?.status === 'SUCCESS');
        // remove success ID
        successID?.forEach((x) => {
          displayedApplicants = displayedApplicants.filter(
            (y) => y.id !== x?.id,
          );
        });

        // remove disabled
        displayedApplicants = displayedApplicants.map((x) => ({
          ...x,
          isDisabled: false,
        }));

        dispatch(setApplicantsDisplayData(displayedApplicants));

        getApplicantsSummary();

        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: data?.message,
          }),
        );
        mpe.applicantBulkProcessError({
          processName: 'applicant bulk process',
          errorResponse: data?.message ?? '',
        });
      }
    }

    // Handle Single
    if (singleUpdate) {
      if (data?.countFailed === 0) {
        dispatch(setApplicantShowSelectBulkErrorCopywriting(false));
        dispatch(setApplicantsFetchStatusDataTable(FETCH_STATUS.PENDING));

        let message = '';
        switch (status) {
          case EpJobApplicationEpStatusEnum.Accepted:
            message = t('applicant:acceptDialog.acceptMessage');
            break;
          case EpJobApplicationEpStatusEnum.Rejected:
            message = t('applicant:rejectDialog.rejectMessage');
            break;
          default:
            message = t('applicant:updateStatusSuccessful');
        }
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'success',
            message: message,
            'data-test-id': 'ctSuccessChangeStatusApplicant',
          }),
        );

        getApplicants();
        getApplicantsSummary();
      }

      if (data?.countFailed) {
        dispatch(setApplicantsDisplayData([...displayData]));
        dispatch(
          setSnackbar({
            layout: 'dashboard',
            severity: 'error',
            message: data.message,
          }),
        );
      }
    }
  };

  const doChangeStatusApplicants = async (
    ids: string[],
    status: EpJobApplicationEpStatusEnum,
    reasons: string[] | null,
  ) => {
    // Use V2 Sync bulk update
    if (isUseApplicantUpdateStatusV2Active) {
      return doChangeStatusApplicantsV2(ids, status, reasons);
    }

    const singleUpdate = ids.length === 1;

    dispatch(setApplicantsMutationStatusUpdateStatus(FETCH_STATUS.PENDING));

    const { isSuccess } = await changeApplicantStatus({
      ids,
      status,
      reasons,
    });

    if (!isSuccess) return;

    dispatch(setApplicantsFetchStatusDataTable(FETCH_STATUS.PENDING));
    dispatch(setApplicantsMutationStatusUpdateStatus(FETCH_STATUS.RESOLVED));
    closeDialogs();

    let message = '';
    switch (status) {
      case EpJobApplicationEpStatusEnum.Accepted:
        message = t('applicant:acceptDialog.acceptMessage');
        break;
      case EpJobApplicationEpStatusEnum.Rejected:
        message = t('applicant:rejectDialog.rejectMessage');
        break;
      default:
        if (singleUpdate) message = t('applicant:updateStatusSuccessful');
        else {
          message = t('applicant:bulkPopup.alert', {
            length: ids.length,
          });
        }
    }

    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: message,
        'data-test-id': 'ctSuccessChangeStatusApplicant',
      }),
    );

    await sleep(1500); // have to delay for BE to process data, confirm with BE before delete.

    getApplicants();
    getApplicantsSummary();
  };

  const doChangeStatusApplicantsFromPopup = async (
    id: string,
    status: EpJobApplicationEpStatusEnum,
  ) => {
    dispatch(setApplicantDetailIsUpdatingStatus(true));
    const { isSuccess, error } = await changeApplicantStatusV2({
      ids: [id],
      status,
      reasons: null,
    });
    dispatch(setApplicantDetailIsUpdatingStatus(false));

    if (isSuccess) {
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'success',
          message: t('applicant:restrictContactPopup.snackbarSuccess'),
          'data-test-id': 'ctSuccessChangeStatusSnackbar',
        }),
      );
    } else {
      dispatch(
        setSnackbar({
          layout: 'dashboard',
          severity: 'error',
          message: error,
        }),
      );
    }
    return isSuccess;
  };

  return {
    changeApplicantStatus: changeApplicantStatusV2,
    doChangeStatusApplicants,
    doChangeStatusApplicantsFromPopup,
    loading,
  };
};
