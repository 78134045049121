import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  brandColors,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '~/components/LoadingButton';
import CloseIcon from '~/components/Icons/CloseIcon';
import WatchLaterIcon from '~/components/Icons/WatchLaterIcon';
import CancelIcon from '~/components/Icons/CancelIcon';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { useActivateJobVacancyHighlight } from '~/hooks/use-job-vacancy-highlight';
import { clearDialog } from '~/store/views/dialogs-slice';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { VacancyHighlightDialogProps } from './VacancyHighlightDialog.types';

import * as mpe from '~/utils/mixpanel';
import { convertDateToISOString } from '~/utils/helper';

const VacancyHighlightDialog = ({
  content,
  ...props
}: VacancyHighlightDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company);
  const { handleActivateVacancy, isLoading } = useActivateJobVacancyHighlight();

  const handleClose = useCallback(
    (trackEvent: boolean) => {
      if (!content) return;
      const { vacancy } = content;

      if (trackEvent)
        mpe.vacancyHighlightCancel({
          vacancyCode: vacancy.code ?? '',
          vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
          vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
        });

      dispatch(clearDialog('vacancyHighlightDialog'));
    },
    [content],
  );

  const handleActivate = useCallback(async () => {
    if (!content) return;
    const { vacancy, onCompleted } = content;
    const vacancyId = vacancy.id;
    if (!vacancyId) return;

    mpe.vacancyHighlightSubmit({
      vacancyCode: vacancy.code ?? '',
      vacancyCreatedAt: convertDateToISOString(vacancy?.createdAt),
      vacancyCloseDate: convertDateToISOString(vacancy?.closeDate),
      companyCode: company?.code ?? '',
      companyName: company?.name ?? '',
    });

    const data = await handleActivateVacancy(vacancyId);
    if (!data?.isSuccess) return;
    dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'success',
        message: t('vacancy:activatePromoteSuccessMessage'),
      }),
    );
    if (onCompleted) onCompleted(() => handleClose(false), data);
  }, [content, handleClose, handleActivateVacancy, company]);

  if (!content) return null;

  return (
    <Dialog
      maxWidth="xs"
      {...props}
      sx={{
        '.MuiPaper-root': {
          maxWidth: { xs: '358px', md: '633px' },
          width: '100%',
          px: { xs: 3, md: '44px' },
          py: { xs: 3, md: '34px' },
          m: { xs: 1, md: 4 },
          borderRadius: 2,
        },
        ...props.sx,
      }}
    >
      <DialogTitle display="flex" justifyContent="flex-end" sx={{ p: 0 }}>
        <IconButton sx={{ mt: -1, mr: -1 }} onClick={() => handleClose(true)}>
          <CloseIcon sx={{ fontSize: '22px', color: 'text.primary' }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Stack>
          <Stack direction="row" gap="14px" sx={{ height: 36 }}>
            <Typography
              component="div"
              variant="h5"
              sx={{ fontSize: '20px', fontWeight: 600 }}
            >
              {t('vacancy:activatePromoteConfirmModalTitle')}
            </Typography>
            <Box
              component="img"
              src="/icon/campaign-icon.svg"
              alt="Campaign"
              width="fit-content"
              height="31px"
            />
          </Stack>
          <Typography
            component="div"
            variant="body2"
            color={brandColors.neutral[800]}
            sx={{ mb: 3 }}
          >
            {t('vacancy:activatePromoteConfirmModalBody')}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color={brandColors.neutral[800]}
            sx={{ mb: '12px' }}
          >
            {t('vacancy:activatePromoteConfirmModalBodyInfo')}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mb: '23px' }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: 37,
                height: 33,
                borderRadius: 1,
                p: '8px 10px',
                background: brandColors.neutral[200],
              }}
            >
              <WatchLaterIcon
                sx={{ fontSize: '18px', color: brandColors.secondary[400] }}
              />
            </Stack>
            <Typography component="div" variant="body2">
              {t('vacancy:activatePromoteConfirmModalBodyPeriodeInfo')}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: 37,
                height: 33,
                borderRadius: 1,
                p: '8px 10px',
                background: brandColors.neutral[200],
              }}
            >
              <CancelIcon
                sx={{ fontSize: '21px', color: brandColors.danger[500] }}
              />
            </Stack>
            <Typography component="div" variant="body2">
              {t('vacancy:activatePromoteConfirmModalBodyAutoCloseInfo')}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{ mt: '34px', p: 0, justifyContent: 'flex-end', gap: '6px' }}
      >
        <LoadingButton
          variant="outlined"
          color="primary"
          onClick={() => handleClose(true)}
          disabled={isLoading}
          autoFocus
          sx={{ width: '148px' }}
        >
          {t('common:cancelAlt')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleActivate}
          loading={isLoading}
          sx={{ width: '148px' }}
        >
          {t('vacancy:promote')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyHighlightDialog;
