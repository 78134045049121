export const kitalulusLink = {
  instagram: 'https://www.instagram.com/kitalulus/',
  tikTok: 'https://www.tiktok.com/@kitalulus?is_from_webapp=1&sender_device=pc',
  x: 'https://x.com/KitaLulus?s=20',
  linkedIn: 'https://www.linkedin.com/company/kitalulusid/mycompany/',
  youtube: 'https://www.youtube.com/c/KitaLulus',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.kitalulus',
  helpCenter: 'https://help.kitalulus.com/',
  helpCenterJobCategory:
    'https://help.kitalulus.com/article/28-apa-saja-jenis-kategori-lowongan-di-dasbor-perusahaan-kitalulus-penjelasan-untuk-tiap-kategorinya',
};
