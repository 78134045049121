import { Middleware } from 'redux';
import {
  IPackageV2,
  IVacanciesData,
  TFirestoreHighlighted,
  setRealtimeDataCompany,
  setRealtimeDataCompanyError,
  setRealtimeDataCompanyFetchStatus,
  setRealtimeDataCompanyUnsubscribe,
  setRealtimeDataHighlightedByCompanyId,
  setRealtimeDataHighlightedByCompanyIdError,
  setRealtimeDataHighlightedByCompanyIdFetchStatus,
  setRealtimeDataHighlightedByCompanyIdUnsubscribe,
  setRealtimeDataPackageByCompanyId,
  setRealtimeDataPackageByCompanyIdError,
  setRealtimeDataPackageByCompanyIdFetchStatus,
  setRealtimeDataPackageByCompanyIdUnsubscribe,
  setRealtimeDataVacancyByCompanyId,
  setRealtimeDataVacancyByCompanyIdError,
  setRealtimeDataVacancyByCompanyIdFetchStatus,
  setRealtimeDataVacancyByCompanyIdUnsubscribe,
} from '~/store/features/realtime-data-slice';
import { firestore } from '~/config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { FETCH_STATUS } from '~/utils/constants';
import { setSnackbar } from '../views/snackbar-slice';
import i18n from '~/utils/i18n';
import { RTD_ACTION_TYPE } from '~/utils/constants/realtime-data';
interface ListenActionCompany {
  type: RTD_ACTION_TYPE.RTD_LISTEN_COMPANY;
  payload: { companyCode: string };
}

interface ListenActionVacancyCompanyId {
  type: RTD_ACTION_TYPE.RTD_LISTEN_VACANCY_BY_COMPANY_ID;
  payload: { companyCode: string; vacancyCode: string };
}

type PayloadAction = {
  type: RTD_ACTION_TYPE;
  payload: { companyCode?: string; vacancyCode?: string };
};

export const realtimeData: Middleware = (storeAPI) => (next) => (action) => {
  const listenAction = action as PayloadAction;

  const showErrorSnackbar = (variant: 'applicant' | 'vacancy') => {
    const isVariantVacancy = variant === 'vacancy';
    storeAPI.dispatch(
      setSnackbar({
        layout: 'dashboard',
        severity: 'error',
        message: i18n
          .t(
            isVariantVacancy
              ? 'business-error:firestoreVacancyLimitError'
              : 'business-error:firestoreApplicantLimitError',
          )
          .toString(),
      }),
    );
  };

  if (listenAction.type === RTD_ACTION_TYPE.RTD_LISTEN_COMPANY) {
    const { companyCode } = (action as ListenActionCompany).payload;

    const docRef = doc(firestore, 'companies', companyCode);

    storeAPI.dispatch(setRealtimeDataCompanyFetchStatus(FETCH_STATUS.PENDING));

    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          storeAPI.dispatch(
            setRealtimeDataCompany({ companyCode, data: doc.data() }),
          );
        } else {
          // Need copy for error on load package
          // showErrorSnackbar('company');
          storeAPI.dispatch(setRealtimeDataCompanyError('No data available'));
        }
      },
      (error) => {
        // Need copy for error on load package
        // showErrorSnackbar('company');
        storeAPI.dispatch(setRealtimeDataCompanyError(error.message));
      },
    );
    storeAPI.dispatch(setRealtimeDataCompanyUnsubscribe(unsubscribe));
  }

  if (listenAction.type === RTD_ACTION_TYPE.RTD_LISTEN_VACANCY_BY_COMPANY_ID) {
    const { companyCode, vacancyCode } = (
      action as ListenActionVacancyCompanyId
    ).payload;

    const docRef = doc(
      firestore,
      'vacanciesByCompanyId',
      companyCode,
      'vacancies',
      vacancyCode,
    );

    storeAPI.dispatch(
      setRealtimeDataVacancyByCompanyIdFetchStatus(FETCH_STATUS.PENDING),
    );

    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          const payload = {
            [vacancyCode]: doc.data() as IVacanciesData,
          };
          storeAPI.dispatch(setRealtimeDataVacancyByCompanyId(payload));
        } else {
          showErrorSnackbar('applicant');
          storeAPI.dispatch(setRealtimeDataCompanyError('No data available'));
        }
      },
      (error) => {
        showErrorSnackbar('applicant');
        storeAPI.dispatch(
          setRealtimeDataVacancyByCompanyIdError(error.message),
        );
      },
    );

    storeAPI.dispatch(
      setRealtimeDataVacancyByCompanyIdUnsubscribe(unsubscribe),
    );
  }

  if (listenAction.type === RTD_ACTION_TYPE.RTD_LISTEN_PACKAGE_BY_COMPANY_ID) {
    const { companyCode } = (action as ListenActionCompany).payload;

    const docRef = doc(firestore, 'packageByCompanyId', companyCode);

    storeAPI.dispatch(
      setRealtimeDataPackageByCompanyIdFetchStatus(FETCH_STATUS.PENDING),
    );

    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          storeAPI.dispatch(
            setRealtimeDataPackageByCompanyId(doc.data() as IPackageV2),
          );
        } else {
          // Need copy for error on load package
          // showErrorSnackbar('package');
          storeAPI.dispatch(
            setRealtimeDataPackageByCompanyIdError('No data available'),
          );
        }
      },
      (error) => {
        // Need copy for error on load package
        // showErrorSnackbar('package');
        storeAPI.dispatch(
          setRealtimeDataPackageByCompanyIdError(error.message),
        );
      },
    );
    storeAPI.dispatch(
      setRealtimeDataPackageByCompanyIdUnsubscribe(unsubscribe),
    );
  }

  if (
    listenAction.type === RTD_ACTION_TYPE.RTD_LISTEN_HIGHLIGHTED_BY_COMPANY_ID
  ) {
    const { companyCode } = (action as ListenActionCompany).payload;

    const docRef = doc(firestore, 'highlightedByCompanyId', companyCode);

    storeAPI.dispatch(
      setRealtimeDataHighlightedByCompanyIdFetchStatus(FETCH_STATUS.PENDING),
    );

    const unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          storeAPI.dispatch(
            setRealtimeDataHighlightedByCompanyId(
              doc.data() as TFirestoreHighlighted,
            ),
          );
        } else {
          // Need copy for error on load package
          // showErrorSnackbar('highlighted');
          storeAPI.dispatch(
            setRealtimeDataHighlightedByCompanyIdError('No data available'),
          );
        }
      },
      (error) => {
        // Need copy for error on load package
        // showErrorSnackbar('highlighted');
        storeAPI.dispatch(
          setRealtimeDataHighlightedByCompanyIdError(error.message),
        );
      },
    );
    storeAPI.dispatch(
      setRealtimeDataHighlightedByCompanyIdUnsubscribe(unsubscribe),
    );
  }

  if (listenAction.type === RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_COMPANY) {
    storeAPI.getState().realtimeData?.company?.unsubscribe?.();
    storeAPI.dispatch(setRealtimeDataCompanyUnsubscribe(null));
  }
  if (
    listenAction.type === RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_VACANCY_BY_COMPANY_ID
  ) {
    storeAPI.getState().realtimeData?.vacancyByCompanyId?.unsubscribe?.();
    storeAPI.dispatch(setRealtimeDataVacancyByCompanyIdUnsubscribe(null));
  }
  if (
    listenAction.type === RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_PACKAGE_BY_COMPANY_ID
  ) {
    storeAPI.getState().realtimeData?.packageByCompanyId?.unsubscribe?.();
    storeAPI.dispatch(setRealtimeDataPackageByCompanyIdUnsubscribe(null));
  }
  if (
    listenAction.type ===
    RTD_ACTION_TYPE.RTD_UNSUBSCRIBE_HIGHLIGHTED_BY_COMPANY_ID
  ) {
    storeAPI.getState().realtimeData?.highlightedByCompanyId?.unsubscribe?.();
    storeAPI.dispatch(setRealtimeDataHighlightedByCompanyIdUnsubscribe(null));
  }
  if (listenAction.type === RTD_ACTION_TYPE.RTD_UNSUBSCRIBE) {
    storeAPI.getState().realtimeData?.company?.unsubscribe?.();
    storeAPI.getState().realtimeData?.vacancyByCompanyId?.unsubscribe?.();
    storeAPI.getState().realtimeData?.packageByCompanyId?.unsubscribe?.();
    storeAPI.getState().realtimeData?.highlightedByCompanyId?.unsubscribe?.();
    storeAPI.dispatch(setRealtimeDataCompanyUnsubscribe(null));
    storeAPI.dispatch(setRealtimeDataVacancyByCompanyIdUnsubscribe(null));
    storeAPI.dispatch(setRealtimeDataPackageByCompanyIdUnsubscribe(null));
    storeAPI.dispatch(setRealtimeDataHighlightedByCompanyIdUnsubscribe(null));
  }

  return next(action);
};

export default realtimeData;
